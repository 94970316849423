import { SearchIcon } from '@/components/LmnIcons/LmnIcons';
import { PassengersSelector } from '@/components/PassengerSelector';
import { useCreateSearch } from '@/hooks/useCreateSearch';
import { useFreeFlightCampaign } from '@/hooks/useFreeFlightCampaign';
import { useTrack } from '@/hooks/useTrack';
import { TravellerGroup } from '@/services/codegen-romulo';
import { useStore } from '@/store/useStore';
import { FlightTypes } from '@/utils/types/FlightTypes';
import { CircularProgress } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import * as React from 'react';
import { useState } from 'react';
import { FlightTypeSelector } from './components/FlightTypeSelector';
import { LocationSelector } from './components/LocationSelector/LocationSelector';
import { ProductSelector } from './components/ProductSelector';
import { useSearchStore } from './hooks/useSearchStore';
import * as Styled from './Search.styled';
import { ProductType } from './Search.types';
import { DateSelector } from './components/DateSelector/DateSelector';
import { useGetParam } from '@/hooks/useGetParam';
import { CalendarSelector } from './CalendarSelector';
import { useCalendarSelector } from './useCalendarSelector';
import { useFlag } from '@unleash/proxy-client-react';

export function Search({
  onSearchIdCreated,
  brandProduct,
  disabledProductSelector,
}: {
  onSearchIdCreated?: () => void;

  brandProduct?: ProductType;
  disabledProductSelector?: boolean;
}) {
  const { t } = useTranslate();

  const isActiveflagMemSearch = useFlag('travel.search.memsearch');

  const [
    departure,
    setDeparture,
    missingDeparture,
    setMissingDeparture,
    missingDestination,
    setMissingDestination,
    destination,
    setDestination,
    hotel,
    setHotel,
    flightType,
    setFlightType,
    loadingSearch,
    setLoadingSearch,
    withHotel,
  ] = useSearchStore((state) => [
    state.departure,
    state.setDeparture,
    state.missingDeparture,
    state.setMissingDeparture,
    state.missingDestination,
    state.setMissingDestination,
    state.destination,
    state.setDestination,
    state.hotel,
    state.setHotel,
    state.flightType,
    state.setFlightType,
    state.loadingSearch,
    state.setLoadingSearch,
    state.withHotel,
  ]);
  const track = useTrack();

  const [productType, setProductType] = React.useState(brandProduct);

  const freeFlightCampaign = useFreeFlightCampaign();

  const brandConfig = useStore((state) => state.brandConfig);

  const { accentColor } = brandConfig.theme;

  const isFreeFlight = freeFlightCampaign?.isFreeFlight;

  const getParam = useGetParam();

  const datefrom = getParam('outboundDate');

  const dateto = getParam('returnDate');

  const roomsState = useState<TravellerGroup[]>([
    {
      adults: freeFlightCampaign?.adults || productType === 'F' ? 1 : 2,
      childrenAges: [],
    },
  ]);

  const [rooms] = roomsState;

  React.useEffect(() => {
    setFlightType(datefrom && !dateto ? FlightTypes.OW : FlightTypes.R);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSearch = useCreateSearch();

  React.useEffect(() => {
    setTimeout(() => {
      setMissingDeparture(false);
      setMissingDestination(false);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingDeparture, missingDestination]);

  const onSearch = async () => {
    if (!departure) {
      setMissingDeparture(true);
    }
    if (!destination) {
      setMissingDestination(true);
    }

    const allFilled = checkStatus();

    if (allFilled) {
      track({
        eventName: 'clicked.search-button',
        logLevel: 'info',
        eventProperties: {
          departure,
          destination,
          dateRange: propsCalendarSelector.propsExactDates.dateRange,
          rooms,
        },
        presearchProduct: productType,
      });
      setLoadingSearch(true);
      await createSearch({
        productType,
        departure,
        destination,
        hotel,
        dateRange: propsCalendarSelector.propsExactDates.dateRange,
        hasFlexiDates: propsCalendarSelector.isVisibleFlexiDates,
        flexiDatesRange: propsCalendarSelector.propsFlexiDates,
        withHotel:
          productType === ProductType.F &&
          flightType === FlightTypes.R &&
          withHotel,
        travellerGroups: rooms,
        flightType: flightType,
      }).finally(() => {
        setLoadingSearch(false);
        onSearchIdCreated?.();
      });
    }
  };

  const checkStatus = (): boolean => {
    switch (productType) {
      case ProductType.DP:
        if (departure && destination && rooms) {
          if (propsCalendarSelector.propsExactDates.dateRange) {
            return !!(
              propsCalendarSelector.propsExactDates.dateRange.from &&
              propsCalendarSelector.propsExactDates.dateRange.to
            );
          }
          return false;
        }
        return false;
      case ProductType.H:
        if (destination && rooms) {
          if (propsCalendarSelector.propsExactDates.dateRange) {
            return !!(
              propsCalendarSelector.propsExactDates.dateRange.from &&
              propsCalendarSelector.propsExactDates.dateRange.to
            );
          }
          return false;
        }
        return false;
      case ProductType.F:
        if (departure && destination && rooms) {
          if (propsCalendarSelector.propsExactDates.dateRange) {
            if (flightType === FlightTypes.OW) {
              return !!propsCalendarSelector.propsExactDates.dateRange.from;
            } else {
              return !!(
                propsCalendarSelector.propsExactDates.dateRange.from &&
                propsCalendarSelector.propsExactDates.dateRange.to
              );
            }
          }
          return false;
        }
        return false;
      default:
        return false;
    }
  };

  const isOneWayTrip =
    productType === ProductType.F && flightType === FlightTypes.OW;

  const isActiveCalendarSelector =
    !isOneWayTrip && isActiveflagMemSearch && productType === ProductType.F;

  const propsCalendarSelector = useCalendarSelector({
    brandConfig,
    isOneWayTrip,
    language: brandConfig.language,
    onChangeFlightType: (flightType) => setFlightType(flightType),
  });

  return (
    <>
      {brandConfig.brand.productsInSelector.length > 0 &&
        !isFreeFlight &&
        !disabledProductSelector && (
          <ProductSelector
            productType={productType}
            onSelectorTab={(product: ProductType) => setProductType(product)}
          />
        )}
      {productType === ProductType.F && !isFreeFlight && (
        <Styled.FlightOptions>
          <FlightTypeSelector
            flightType={flightType}
            onFlightSelector={
              propsCalendarSelector.propsExactDates.onFlightSelector
            }
          />
          {/* {flightType === FlightTypes.R && (
            <Styled.Switch>
              {t('search.add.hotel')}
              <Switch
                value={withHotel}
                disableRipple
                onChange={(_, active) => setWithHotel(active)}
              />
            </Styled.Switch>
          )} */}
        </Styled.FlightOptions>
      )}
      <Styled.SearchContainer $isFreeFlight={isFreeFlight}>
        <LocationSelector
          productType={productType}
          setDeparture={setDeparture}
          setDestination={setDestination}
          setHotel={setHotel}
          missingDeparture={missingDeparture}
          missingDestination={missingDestination}
        />
        {isActiveCalendarSelector && (
          <CalendarSelector
            isOneWayTrip={isOneWayTrip}
            productType={productType}
            calendarSelectorText={propsCalendarSelector.calendarSelectorText}
            onClickCalendarSelectorInput={
              propsCalendarSelector.onClickCalendarSelectorInput
            }
            propsExactDates={propsCalendarSelector.propsExactDates}
            propsFlexiDates={propsCalendarSelector.propsFlexiDates}
            isOpenCalendarSelector={
              propsCalendarSelector.isVisibleCalendarSelector
            }
            onChangeVisibleFlexiDates={
              propsCalendarSelector.onChangeVisibleFlexiDates
            }
            onCloseCalendarSelector={
              propsCalendarSelector.closeCalendarSelector
            }
            isVisibleFlexiDates={propsCalendarSelector.isVisibleFlexiDates}
          />
        )}
        {!isActiveCalendarSelector && (
          <DateSelector
            isOneWayTrip={isOneWayTrip}
            dateRange={propsCalendarSelector.propsExactDates.dateRange}
            onSelectedDateRange={
              propsCalendarSelector.propsExactDates.onSelectedDateRange
            }
            productType={productType}
          />
        )}

        {!isFreeFlight && (
          <PassengersSelector
            roomsState={roomsState}
            productType={productType}
          />
        )}
        <Styled.Button
          onClick={onSearch}
          disabled={loadingSearch}
          data-test="search-form-search-button"
          $color={accentColor}
        >
          {loadingSearch ? (
            <CircularProgress
              sx={{
                color: 'white',
                width: '24px !important',
                height: '24px !important',
              }}
            />
          ) : (
            <SearchIcon size={24} />
          )}
          <Styled.SearchText>{t('search.search')}</Styled.SearchText>
        </Styled.Button>
      </Styled.SearchContainer>
      {/* {bookingType === BookingType.Flight &&
        flightType === FlightTypes.R &&
        !isFreeFlight && (
          <Styled.SwitchMobile>
            {t('search.add_hotel')}
            <Switch
              value={withHotel}
              disableRipple
              onChange={(_, active) => setWithHotel(active)}
            />
          </Styled.SwitchMobile>
        )} */}
    </>
  );
}
